
































import { Vue, Component, Prop } from 'vue-property-decorator';
import Card from './Card.vue';
import Icon from '@/shared/resources/components/Icon.vue';
import { isNumber } from '@/core/helpers/utils/NumberUtils';
import Loader from '@/shared/resources/components/Loader.vue';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';

const ANIMATION_DURATION_SECONDS: number = 1;

@Component({
  components: {
    Loader,
    Icon,
    Card,
  },
})
export default class CardIconInfo extends Vue {
  /**
   * Props
   */
  @Prop() private title!: string;
  @Prop() private value?: string | number;
  @Prop() private icon!: string;
  @Prop() private query?: () => Promise<any>;
  @Prop({ default: 'primary' }) private color!: string;
  @Prop({ default: 'white' }) private textColor!: string;
  @Prop({ default: 80 }) private size!: number;
  @Prop({ default: false, type: Boolean }) private animate!: boolean;

  /**
   * Data
   */
  private valueToDisplay: string | number | null = null;
  private loading: boolean = false;

  /**
   * Display getters
   */
  private get displayValue(): boolean {
    return !this.loading;
  }

  private get displayLoader(): boolean {
    return this.loading;
  }

  /**
   * Class names
   */
  private get iconClassNames(): object {
    return {
      [this.textColor + '--text']: true,
    };
  }

  /**
   * Lifecycle hooks
   */
  private created() {
    if (this.value) {
      this.fillValue(this.value);
    } else if (this.query) {
      this.runQuery();
    } else {
      this.valueToDisplay = '-';
    }
  }

  /**
   * Methods
   */
  private fillValue(value: string | number) {
    if (isNumber(value)) {
      if (value === 0) {
        this.valueToDisplay = '-';
      } else if (this.animate) {
        this.animateValue(value as number);
      } else {
        this.valueToDisplay = value;
      }
    } else {
      this.valueToDisplay = value;
    }
  }

  private async runQuery() {
    this.loading = true;

    try {
      const response: number = await this.query!();
      this.fillValue(response);
    } catch (ex) {
      // TODO
      this.valueToDisplay = '-';
    }

    this.loading = false;
  }

  private animateValue(value: number) { // TODO make component/directive
    this.valueToDisplay = 0;

    const start: number = 0;
    const end: number = value;
    const duration: number = ANIMATION_DURATION_SECONDS * 1000;
    let startTime: number | null = null;

    const count = (timestamp: number) => {
      if (!startTime) {
        startTime = timestamp;
      }

      const progress: number = timestamp - startTime;
      const nextValue = start + (end - start) * (progress / duration);

      if (progress < duration) {
        this.valueToDisplay = Math.round(nextValue);
        requestAnimationFrame(count);
      } else {
        this.valueToDisplay = end;
      }
    };

    requestAnimationFrame(count);
  }
}
